import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import SupportersCard from '../SupportersCard/SupportersCard';
import './media-section.scss';
import { useTranslation } from 'react-i18next';

const MediaSection = () => {
    const { t } = useTranslation();
    const [currentSlide, setCurrentSlide] = useState(0);

  const media = [
    {
      logo: require('../../assets/images/media/voice.png'),
      name: "USAID"
    },
    {
      logo: require('../../assets/images/media/monde.png'),
      name: "Creative"
    },
    {
      logo: require('../../assets/images/media/quartz.png'),
      name: "ABT"
    },
    {
      logo: require('../../assets/images/media/cnn.png'),
      name: "Novartis Foundation"
    },
    {
      logo: require('../../assets/images/media/dw.jpeg'),
      name: "Cisco"
    },
    {
      logo: require('../../assets/images/media/rfi.png'),
      name: "Expo Live"
    }
  ];

  const cardsToShow = 5; // Number of cards to show at once
  const maxSlide = media.length - cardsToShow;

  const nextSlide = () => {
    setCurrentSlide(prev => Math.min(prev + 1, maxSlide));
  };

  const prevSlide = () => {
    setCurrentSlide(prev => Math.max(prev - 1, 0));
  };

  return (
    <section className="media-section">
      <div className="media-section__container">
        <div className="media-section__header">
          <h2 className="media-section__title">{t('media.title')}</h2>
          <p className="media-section__subtitle">{t('media.subtitle')}</p>
        </div>

        <div className="media-section__carousel">
          <button 
            className="media-section__nav media-section__nav--prev"
            onClick={prevSlide}
            disabled={currentSlide === 0}
            aria-label="Previous media"
          >
            <ChevronLeft size={16} />
          </button>

          <div className="media-section__track-container">
            <div 
              className="media-section__track"
              style={{ 
                transform: `translateX(-${currentSlide * (100 / cardsToShow)}%)`
              }}
            >
              {media.map((supporter, index) => (
                <div 
                  key={index}
                  className="media-section__slide"
                >
                  <SupportersCard {...supporter} />
                </div>
              ))}
            </div>
          </div>

          <button 
            className="media-section__nav media-section__nav--next"
            onClick={nextSlide}
            disabled={currentSlide === maxSlide}
            aria-label="Next media"
          >
            <ChevronRight size={16} />
          </button>
        </div>
      </div>
    </section>
  );
};

export default MediaSection;