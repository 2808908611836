import React from 'react';
import { Navbar, PageHeader, PublicationSection } from '../../components';

const Publications = () => {
    return (
        <div>
            <Navbar />
            <PageHeader title="Publications" />
            <PublicationSection />
        </div>
    );
}

export default Publications;
