import React from 'react';
import './home-services-card.scss';

  const HomeServicesCard = ({ image, title, subtitle, description }) => {
    return (
      <div className="services-card">
        <div className="services-card__icon">
          <img 
            src={image}
            alt={''} 
            className="services-card__icon-image"
          />
        </div>
        <div className="services-card__content">
          <h3 className="services-card__title">{title}</h3>
          <h4 className="services-card__subtitle">{subtitle}</h4>
          <p className="services-card__description">{description}</p>
        </div>
      </div>
    );
  };
  
  export default HomeServicesCard;