import React from 'react';
import { useTranslation } from 'react-i18next';
import FutureItem from '../FutureItem/FutureItem';
import './home-future-section.scss';

const HomeFuturesSection = () => {
  const { t } = useTranslation();

  const futuresData = [
    {
      image: require('../../assets/images/left_1.png'),
      title: t('homeFutures.items.simulations.title'),
      description: t('homeFutures.items.simulations.description'),
      link: t('homeFutures.items.simulations.link'),
      imagePosition: 'left'
    },
    {
      image: require('../../assets/images/products/product_5_2.png'),
      title: t('homeFutures.items.gmScope.title'),
      description: t('homeFutures.items.gmScope.description'),
      link: t('homeFutures.items.gmScope.link'),
      imagePosition: 'right'
    },
    {
      image: require('../../assets/images/products/product_5_3.png'),
      title: t('homeFutures.items.biopsy.title'),
      description: t('homeFutures.items.biopsy.description'),
      link: t('homeFutures.items.biopsy.link'),
      imagePosition: 'left'
    }
  ];

  return (
    <section className="home-futures">
      <div className="home-futures__container">
        <div className="home-futures__header">
          <h2 className="home-futures__title">
            {t('homeFutures.title')}
          </h2>
          <p className="home-futures__subtitle">
            {t('homeFutures.subtitle')}
          </p>
        </div>

        <div className="home-futures__items">
          {futuresData.map((item, index) => (
            <FutureItem
              key={index}
              {...item}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default HomeFuturesSection;