import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import SupportersCard from '../SupportersCard/SupportersCard';
import './supporters-section.scss';
import { useTranslation } from 'react-i18next';

const SupportersSection = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { t } = useTranslation();

  const supporters = [
    {
      logo: require('../../assets/images/supporters/usaid.png'),
      name: "USAID"
    },
    {
      logo: require('../../assets/images/supporters/ifpma.png'),
      name: "IFPMA"
    },
    {
      logo: require('../../assets/images/supporters/investing_in_inovation.png'),
      name: "IFPMA"
    },
    {
      logo: require('../../assets/images/supporters/creative.png'),
      name: "Investing In Inovation"
    },
    {
      logo: require('../../assets/images/supporters/abt.png'),
      name: "ABT"
    },
    {
      logo: require('../../assets/images/supporters/novartis.png'),
      name: "Novartis Foundation"
    },
    {
      logo: require('../../assets/images/supporters/cisco.png'),
      name: "Cisco"
    },
    {
      logo: require('../../assets/images/supporters/bridge_for_billions.png'),
      name: "Bridge For Billions"
    }
  ];

  const cardsToShow = 5; // Number of cards to show at once
  const maxSlide = supporters.length - cardsToShow;

  const nextSlide = () => {
    setCurrentSlide(prev => Math.min(prev + 1, maxSlide));
  };

  const prevSlide = () => {
    setCurrentSlide(prev => Math.max(prev - 1, 0));
  };

  return (
    <section className="supporters-section">
      <div className="supporters-section__container">
        <div className="supporters-section__header">
          <h2 className="supporters-section__title">{t('supporters.title')}</h2>
          <p className="supporters-section__subtitle"> {t('supporters.subtitle')}</p>
        </div>

        <div className="supporters-section__carousel">
          <button 
            className="supporters-section__nav supporters-section__nav--prev"
            onClick={prevSlide}
            disabled={currentSlide === 0}
            aria-label="Previous supporters"
          >
            <ChevronLeft size={16} />
          </button>

          <div className="supporters-section__track-container">
            <div 
              className="supporters-section__track"
              style={{ 
                transform: `translateX(-${currentSlide * (100 / cardsToShow)}%)`
              }}
            >
              {supporters.map((supporter, index) => (
                <div 
                  key={index}
                  className="supporters-section__slide"
                >
                  <SupportersCard {...supporter} />
                </div>
              ))}
            </div>
          </div>

          <button 
            className="supporters-section__nav supporters-section__nav--next"
            onClick={nextSlide}
            disabled={currentSlide === maxSlide}
            aria-label="Next supporters"
          >
            <ChevronRight size={16} />
          </button>
        </div>
      </div>
    </section>
  );
};

export default SupportersSection;