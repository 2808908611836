import React from 'react';
import { Users, Globe, HandHeart, Lightbulb } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import './strategy-section.scss';
import StrategyCard from '../StrategyCard/StrategyCard';

const StrategySection = () => {
  const { t } = useTranslation();

  const strategies = [
    {
      icon: <Users />,
      title: t('strategy.cards.partnerships.title'),
      description: t('strategy.cards.partnerships.description')
    },
    {
      icon: <Globe />,
      title: t('strategy.cards.access.title'),
      description: t('strategy.cards.access.description')
    },
    {
      icon: <HandHeart />,
      title: t('strategy.cards.empowerment.title'),
      description: t('strategy.cards.empowerment.description')
    },
    {
      icon: <Lightbulb />,
      title: t('strategy.cards.innovation.title'),
      description: t('strategy.cards.innovation.description')
    }
  ];

  return (
    <section className="strategy">
      <div className="strategy__container">
        <h2 className="strategy__title">{t('strategy.title')}</h2>
        <div className="strategy__grid">
          {strategies.map((strategy, index) => (
            <StrategyCard
              key={index}
              icon={strategy.icon}
              title={strategy.title}
              description={strategy.description}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default StrategySection;