import React from 'react';
import { useTranslation } from 'react-i18next';
import './home-gallery-section.scss';

const HomeGallerySection = () => {
  const { t } = useTranslation();

  return (
    <section className="home-gallery">
      <div className="home-gallery__container">
        <div className="home-gallery__content">
          <h2 className="home-gallery__title">
            {t('homeGallery.title')}
          </h2>
          
          <div className="home-gallery__text">
            <p>{t('homeGallery.paragraphs.first')}</p>
            <p>{t('homeGallery.paragraphs.second')}</p>
            <p>{t('homeGallery.paragraphs.third')}</p>
          </div>
        </div>

        <div className="home-gallery__images">
          <div className="home-gallery__image-large">
            <img 
              src={require('../../assets/images/gallery/gallery_1.png')} 
              alt={t('homeGallery.altText.main')} 
            />
          </div>
          <div className="home-gallery__image-grid">
            <div className="home-gallery__image home-gallery__image--top">
              <img 
                src={require('../../assets/images/gallery/gallery_3.png')} 
                alt={t('homeGallery.altText.phoneMount')} 
              />
            </div>
            <div className="home-gallery__image home-gallery__image--bottom">
              <img 
                src={require('../../assets/images/gallery/gallery_5.png')} 
                alt={t('homeGallery.altText.clinical')} 
              />
            </div>
          </div>
          <div className="home-gallery__image-grid-right">
            <div className="home-gallery__image home-gallery__image--top-right">
              <img 
                src={require('../../assets/images/gallery/gallery_4.png')} 
                alt={t('homeGallery.altText.closeUp')} 
              />
            </div>
            <div className="home-gallery__image home-gallery__image--bottom-right">
              <img 
                src={require('../../assets/images/gallery/gallery_2.png')} 
                alt={t('homeGallery.altText.application')} 
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeGallerySection;