import React from 'react';
import './product-section-four.scss';
import YouTube from 'react-youtube';

const ProductSectionFour = () => {
    const videoId = 'pAWoi3lwJS4';

    const opts = {
        width: '100%',
        height: '100%',
        playerVars: {
          autoplay: 0,
          modestbranding: 1,
          controls: 1,
          rel: 0,
        },
      };

  return (
    <section className="product-section-four">
      <div className="product-section-four__container">
        <p className="product-section-four__intro">
          Now, everywoman, no matter her location or social status can have access to screening and diagnosis at the point of care, in the same community where she lives, faster and cheaper.
        </p>

        {/* <div className="product-section-four__healthcare-banner">
          <img 
            src={require('../../assets/images/products/product_4_1.png')}
            alt="Healthcare facility illustration" 
            className="product-section-four__banner-image"
          />
        </div> */}

    <div className="product-section-four__video-container">
          <YouTube
            videoId={videoId}
            opts={opts}
            className="product-section-four__video-player"
          />
        </div>

        <div className="product-section-four__app-showcase">
          <div className="product-section-four__app-images">
            <div className="product-section-four__phones">
              <img 
                 src={require('../../assets/images/products/product_4_2.png')}
                alt="Mobile app screen 1" 
                className="product-section-four__phone"
              />

            </div>
          </div>
          <div className="product-section-four__app-content">
            <p className="product-section-four__app-description">
              In addition to the innovative smart speculum, we are proud to introduce our cutting-edge telemedicine platform, designed to enhance access to cervical cancer care and support for women everywhere. This platform allows healthcare providers to connect with patients remotely, facilitating timely consultations and follow-ups that break down geographic barriers. By leveraging AI, we aim to improve diagnostic accuracy, personalize patient interactions, and streamline clinical workflows, ensuring that women receive the most effective and efficient care possible, no matter where they are.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductSectionFour;