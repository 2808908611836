import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Phone, Mail } from 'lucide-react';
import './contact-section.scss';

const contactSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('First name is required'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Last name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  phone: Yup.string()
    .matches(/^[0-9\s+-]+$/, 'Invalid phone number')
    .required('Phone number is required'),
  country: Yup.string()
    .required('Country is required'),
  organization: Yup.string()
    .required('Organization is required'),
  message: Yup.string()
    .min(10, 'Message is too short')
    .required('Message is required'),
});

const ContactSection = () => {
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    console.log(values);
    // Handle form submission here
    setSubmitting(false);
    resetForm();
  };

  return (
    <section className="contact">
      <div className="contact__container">
        <div className="contact__image-wrapper">
          <img 
            src={require('../../assets/images/contact.png')}
            alt="Doctor with stethoscope"
            className="contact__image"
          />
        </div>

        <div className="contact__content">
          <h2 className="contact__title">Contact Us</h2>
          <p className="contact__description">
          Contact us directly or fill out the form below, and we'll respond promptly!
          </p>

          <div className="contact__info">
            <div className="contact__info-item">
              <div className='contact__info-icon-container'>
                <Phone className="contact__info-icon" />
              </div>
              <span>+237 658623816</span>
            </div>
            <div className="contact__info-item">
              <div className='contact__info-icon-container'>
                <Mail className="contact__info-icon" />
              </div>
              <span>info@gictelemed.org</span>
            </div>
          </div>

          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              phone: '',
              country: '',
              organization: '',
              message: ''
            }}
            validationSchema={contactSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form className="contact__form">
                <div className="contact__form-grid">
                  <div className="contact__form-group">
                    <Field
                      name="firstName"
                      placeholder="First Name"
                      className={`contact__form-input ${errors.firstName && touched.firstName ? 'contact__form-input--error' : ''}`}
                    />
                    {errors.firstName && touched.firstName && (
                      <div className="contact__form-error">{errors.firstName}</div>
                    )}
                  </div>

                  <div className="contact__form-group">
                    <Field
                      name="lastName"
                      placeholder="Last Name"
                      className={`contact__form-input ${errors.lastName && touched.lastName ? 'contact__form-input--error' : ''}`}
                    />
                    {errors.lastName && touched.lastName && (
                      <div className="contact__form-error">{errors.lastName}</div>
                    )}
                  </div>

                  <div className="contact__form-group">
                    <Field
                      name="email"
                      type="email"
                      placeholder="Email"
                      className={`contact__form-input ${errors.email && touched.email ? 'contact__form-input--error' : ''}`}
                    />
                    {errors.email && touched.email && (
                      <div className="contact__form-error">{errors.email}</div>
                    )}
                  </div>

                  <div className="contact__form-group">
                    <Field
                      name="phone"
                      placeholder="Phone Number"
                      className={`contact__form-input ${errors.phone && touched.phone ? 'contact__form-input--error' : ''}`}
                    />
                    {errors.phone && touched.phone && (
                      <div className="contact__form-error">{errors.phone}</div>
                    )}
                  </div>

                  <div className="contact__form-group">
                    <Field
                      name="country"
                      placeholder="Country"
                      className={`contact__form-input ${errors.country && touched.country ? 'contact__form-input--error' : ''}`}
                    />
                    {errors.country && touched.country && (
                      <div className="contact__form-error">{errors.country}</div>
                    )}
                  </div>

                  <div className="contact__form-group">
                    <Field
                      name="organization"
                      placeholder="Organization"
                      className={`contact__form-input ${errors.organization && touched.organization ? 'contact__form-input--error' : ''}`}
                    />
                    {errors.organization && touched.organization && (
                      <div className="contact__form-error">{errors.organization}</div>
                    )}
                  </div>
                </div>

                <div className="contact__form-group">
                  <Field
                    name="message"
                    as="textarea"
                    placeholder="Your Message"
                    className={`contact__form-input contact__form-textarea ${errors.message && touched.message ? 'contact__form-input--error' : ''}`}
                  />
                  {errors.message && touched.message && (
                    <div className="contact__form-error">{errors.message}</div>
                  )}
                </div>

                <button 
                  type="submit" 
                  className="contact__form-submit"
                  disabled={isSubmitting}
                >
                  Send
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;