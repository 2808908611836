import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import './navbar.scss';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  
  // Check if we're on mobile
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  // Close menu when clicking outside
  useEffect(() => {
    const closeMenu = (e) => {
      if (!e.target.closest('.navbar__links') && !e.target.closest('.navbar__mobile-toggle')) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('click', closeMenu);
    return () => document.removeEventListener('click', closeMenu);
  }, []);

  const toggleLanguage = () => {
    if (isMobile) {
      setIsLanguageOpen(!isLanguageOpen);
    }
  };

  const handleLanguageHover = (isHovering) => {
    if (!isMobile) {
      setIsLanguageOpen(isHovering);
    }
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsLanguageOpen(false);
    localStorage.setItem('language', lng);
  };

  // Set initial language from localStorage
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);


  return (
    <nav className="navbar">
    <div className="navbar__container">
      <Link to="/" className="navbar__brand">
        <img 
          src={require('../../assets/logo/logo.png')} 
          alt="GICMED Logo" 
          className="navbar__logo" 
        />
      </Link>
      
      <div className={`navbar__links ${isMenuOpen ? 'navbar__links--open' : ''}`}>
        <Link 
          to="/" 
          className={`navbar__link ${location.pathname === '/' ? 'navbar__link--active' : ''}`}
        >
          {t('nav.home')}
        </Link>
        <a 
          href="#about" 
          className={`navbar__link ${location.pathname === '/about' ? 'navbar__link--active' : ''}`}
        >
          {t('nav.about')}
        </a>
        <Link 
          to="/products" 
          className={`navbar__link ${location.pathname === '/products' ? 'navbar__link--active' : ''}`}
        >
          {t('nav.products')}
        </Link>
        <Link 
          to="/publications" 
          className={`navbar__link ${location.pathname === '/publications' ? 'navbar__link--active' : ''}`}
        >
          {t('nav.publications')}
        </Link>
        <Link 
          to="/contact" 
          className={`navbar__link ${location.pathname === '/contact' ? 'navbar__link--active' : ''}`}
        >
          {t('nav.contact')}
        </Link>
        
        <div 
          className="navbar__language"
          onMouseEnter={() => handleLanguageHover(true)}
          onMouseLeave={() => handleLanguageHover(false)}
        >
          <button 
            className={`navbar__language-button ${isLanguageOpen ? 'navbar__language-button--active' : ''}`}
            onClick={toggleLanguage}
          >
            {t('nav.language')}
            {isMobile && (
              isLanguageOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />
            )}
          </button>
          {isLanguageOpen && (
            <div className="navbar__language-dropdown">
              <button 
                className={`navbar__language-option ${i18n.language === 'en' ? 'navbar__language-option--active' : ''}`}
                onClick={() => changeLanguage('en')}
              >
                {t('languages.en')}
              </button>
              <button 
                className={`navbar__language-option ${i18n.language === 'fr' ? 'navbar__language-option--active' : ''}`}
                onClick={() => changeLanguage('fr')}
              >
                {t('languages.fr')}
              </button>
            </div>
          )}
        </div>
      </div>
      
      <button 
        className="navbar__mobile-toggle"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        aria-label="Toggle menu"
      >
        <span className="navbar__mobile-toggle-icon"></span>
      </button>
    </div>
  </nav>
  );
};

export default Navbar;