import React from 'react';
import { Navbar, Banner, AboutSection, StrategySection, HomeGallerySection, HomeServicesSection, HomeFuturesSection, TestimonialSection, SupportersSection, MediaSection, Footer } from '../../components';

const Home = () => {
    return (
        <div>
            <Navbar />
            <Banner />
            <AboutSection />
            <StrategySection />
            <HomeGallerySection />
            <HomeServicesSection />
            <HomeFuturesSection />
            <TestimonialSection />
            <SupportersSection />
            <MediaSection />
            <Footer />
            {/* <h1>Home Component</h1> */}
        </div>
    );
}

export default Home;
