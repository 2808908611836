import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import { Home, Products, Publications, Contact, About, PublicationDetailsPage } from './pages';


import './App.css';
import './scss/main.scss';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
            <Route path="/" element={(<Home />)} />
            <Route path="/about" element={(<About />)} />
            <Route path="/products" element={(<Products />)} />
            <Route path="/publications" element={(<Publications />)} />
            <Route path="/publication/:id" element={(<PublicationDetailsPage />)} />
            <Route path="/contact" element={(<Contact />)} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
