import React from 'react';
import { Calendar } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import './publication_card.scss';

const PublicationCard = ({ id, image, shortTitle, shortDescription, date }) => {
    const navigate = useNavigate();

  return (
    <div className="publication-card" onClick={() => navigate(`/publication/${id}`)}>
      <div className="publication-card__image">
        <img src={require("../../assets/images/publications/publication_1.jpg")} alt={shortTitle} />
      </div>
      <div className="publication-card__content">
        <h3 className="publication-card__title">{shortTitle}</h3>
        <p className="publication-card__description">{shortDescription}</p>
        
        <div className="publication-card__date">
          <Calendar size={16} />
          <span>{date}</span>
        </div>
      </div>
    </div>
  );
};

export default PublicationCard;