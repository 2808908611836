import React from 'react';
import { Play } from 'lucide-react';
import './product-section-five.scss';
import { useTranslation } from 'react-i18next';

const ProductItem = ({ title, description, image, buttons, imagePosition = 'right' }) => {
  return (
    <div className={`product-item ${imagePosition === 'left' ? 'product-item--reverse' : ''}`}>
      <div className="product-item__content">
        <h3 className="product-item__title">{title}</h3>
        <p className="product-item__description">{description}</p>
        <div className="product-item__buttons">
          {buttons.map((button, index) => (
            <button 
              key={index}
              className={`product-item__button ${button.variant === 'outline' ? 'product-item__button--outline' : ''}`}
              onClick={()=> window.open(`${button.link}`, '_blank')}
            >
              {button.icon}
              {button.text}
            </button>
          ))}
        </div>
      </div>
      <div className="product-item__image-wrapper">
        <img src={image} alt={title} className="product-item__image" />
      </div>
    </div>
  );
};

const ProductSectionFive = () => {
  const { t } = useTranslation();

  const innovations = [
    {
      title: "Clever Simulations :",
      description: "Gamified 3D simulations for early breast cancer detection and personalized patient navigation.",
      image: require('../../assets/images/products/product_5_1.png'),
      imagePosition: 'right',
      buttons: [
        { text: "Learn More", variant: "solid", link: t('homeFutures.items.simulations.link') },
        { 
          text: "Play Video", 
          variant: "outline",
          icon: <Play size={16} className="button-icon" />,
          link: 'https://youtu.be/rRNGrr5974U?si=cgzQFg9dglUv_g0z'
        }
      ]
    },
    {
      title: "GM Scope :",
      description: "Smart, Accessible, and Designed for the Realities of Healthcare in Africa: revolutionizes pathology and microscopy by delivering telepathology and tele-microscopy to underserved areas.",
      image:  require('../../assets/images/products/product_5_2.png'),
      imagePosition: 'left',
      buttons: [
        { text: "Learn More", variant: "solid", link: t('homeFutures.items.gmScope.link') }
      ]
    },
    {
      title: "Biopsy Devices",
      description: "Precision and efficiency in tissue sampling for better diagnoses",
      image:  require('../../assets/images/products/product_5_3.png'),
      imagePosition: 'right',
      buttons: [
        { text: "Learn More", variant: "solid", link: t('homeFutures.items.biopsy.link') }
      ]
    }
  ];

  return (
    <section className="product-section-five">
      <div className="product-section-five__container">
        <h2 className="product-section-five__title">
          EXPLORE OTHER INNOVATIONS
        </h2>

        <div className="product-section-five__innovations">
          {innovations.map((innovation, index) => (
            <ProductItem
              key={index}
              {...innovation}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProductSectionFive;