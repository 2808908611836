import React from 'react';
import { Navbar, PageHeader, ProductSectionOne, ProductSectionTwo, ProductSectionThree, ProductSectionFour, ProductSectionFive, ContactSection, Footer } from '../../components';

const Products = () => {
    return (
        <div>
            <Navbar />
            <PageHeader title="Products" />
            <ProductSectionOne />
            <ProductSectionTwo />
            <ProductSectionThree />
            <ProductSectionFour />
            <ProductSectionFive />
            <ContactSection />
            <Footer />
        </div>
    );
}

export default Products;
