import React from 'react';
import { Facebook, Twitter, Instagram, Calendar } from 'lucide-react';
import { Link } from 'react-router-dom';
import publicationsData from '../../data/publications.json';
import './publication_detail.scss';

const RelatedPublicationCard = ({ id, image, title, date }) => (
  <Link to={`/publication/${id}`} className="related-publication">
    <div className="related-publication__image">
      <img src={image} alt={title} />
    </div>
    <div className="related-publication__content">
      <h4>{title}</h4>
      <div className="related-publication__date">
        <Calendar size={14} />
        <span>{date}</span>
      </div>
    </div>
  </Link>
);

const PublicationDetail = ({ id }) => {
  const publication = publicationsData.publications.find(pub => pub.id === id);
  const relatedPublications = publicationsData.publications.filter(pub => pub.id !== id);

  if (!publication) return <div>Publication not found</div>;

  return (
    <div className="publication-detail">
      <div className="publication-detail__breadcrumb">
        <Link to="/publications">Publications</Link>
        <span>›</span>
        <span>{publication.shortTitle}</span>
      </div>

      <div className="publication-detail__header">
        <img 
          src={require("../../assets/images/publications/publication_1.jpg")} 
          alt={publication.title}
          className="publication-detail__header-image" 
        />
        <div className="publication-detail__date">{publication.date}</div>
      </div>

      <div className="publication-detail__layout">
        <div className="publication-detail__content">
          <h1 className="publication-detail__title">{publication.title}</h1>

          <div className="publication-detail__text">
            <p>{publication.fullDescription}</p>
            
            <h4>Objective:</h4>
            <p>{publication.objectives}</p>

            <h4>Study Design:</h4>
            <p>{publication.design}</p>
            <br/>
            <p>Patient comfort levels were assessed using a visual analogue scale, while the percentage
visualization of the cervix was determined following the insertion of each speculum. The
results of cervical cancer screening using VIA were compared between the standard-of-care
procedure and the novel smart speculum integrated with telemedicine.</p>

            <h4>Patient Population:</h4>
            <p>{publication.results}</p>

            <div style={{paddingLeft: '15px'}}>
              <ol>
                <li>The proportion of clinicians reporting easy visualization of the cervix with the two
                types of specula.</li>
                <li>The level of patient comfort with the novel smart speculum compared to the
                standard-of-care speculum (traditional speculum).</li>
                <li>
                The reliability of the novel smart speculum in conducting cervical cancer screening
                using telemedicine.
                </li>
              </ol>
            </div>

            <h4>Ethical Considerations:</h4>
            <p>{publication.conclusions}</p>

            <p className="publication-detail__reference">{publication.reference} <a href="https://clinicaltrials.gov/study/NCT06359431">ClinicalTrials.gov page.</a></p>
          </div>
        </div>

        <aside className="publication-detail__sidebar">
          <div className="publication-detail__social">
            <h3>Follow Us</h3>
            <div className="publication-detail__social-links">
              <a href="#"><Facebook /></a>
              <a href="#"><Twitter /></a>
              <a href="#"><Instagram /></a>
            </div>
          </div>

          <div className="publication-detail__related">
            <h3 className='sidebar_title'>Our Products</h3>
            <div className="publication-detail__related-list">
              {relatedPublications.map(pub => (
                <RelatedPublicationCard key={pub.id} {...pub} />
              ))}
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
};


export default PublicationDetail;