import React from 'react';
import './supporters-card.scss';

const SupportersCard = ({ logo, name }) => {
  return (
    <div className="supporters-card">
      <img 
        src={logo} 
        alt={`${name} logo`} 
        className="supporters-card__logo"
      />
    </div>
  );
};

export default SupportersCard;