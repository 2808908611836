// HomeServicesSection.jsx
import React from 'react';
import { useTranslation } from 'react-i18next';
import HomeServicesCard from '../HomeServicesCard/HomeServicesCard';
import './home-services-section.scss';

const HomeServicesSection = () => {
  const { t } = useTranslation();

  const services = [
    {
      image: require('../../assets/images/service_1.png'),
      title: t('homeServices.cards.women.title'),
      subtitle: t('homeServices.cards.women.subtitle'),
      description: t('homeServices.cards.women.description')
    },
    {
      image: require('../../assets/images/service_2.png'),
      title: t('homeServices.cards.clinicians.title'),
      subtitle: t('homeServices.cards.clinicians.subtitle'),
      description: t('homeServices.cards.clinicians.description')
    },
    {
      image: require('../../assets/images/service_3.png'),
      title: t('homeServices.cards.revolution.title'),
      subtitle: t('homeServices.cards.revolution.subtitle'),
      description: t('homeServices.cards.revolution.description')
    }
  ];

  return (
    <section className="home-services">
      <div className="home-services__container">
        <div className="home-services__header">
          <h2 className="home-services__title">
            {t('homeServices.title')}
          </h2>
          <p className="home-services__subtitle">
            {t('homeServices.subtitle')}
          </p>
        </div>

        <div className="home-services__grid">
          {services.map((service, index) => (
            <HomeServicesCard
              key={index}
              {...service}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default HomeServicesSection;