import React from 'react';
import './strategy-card.scss';

const StrategyCard = ({ icon, title, description }) => {
  return (
    <div className="strategy-card">
      <div className="strategy-card__icon">
        {icon}
      </div>
      <h3 className="strategy-card__title">{title}</h3>
      <p className="strategy-card__description">{description}</p>
    </div>
  );
};


export default StrategyCard;