// Banner.jsx
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './banner.scss';

const Banner = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="banner">
      <div className="banner__container">
        <div className="banner__content">
          <h1 className="banner__title">
            {t('banner.title')}
          </h1>
          <p className="banner__subtitle">
            {t('banner.subtitle')}
          </p>
          <button onClick={() => navigate('/products')} className="banner__button">
            {t('banner.button')}
          </button>
        </div>
        
        <div className="banner__images-container">
          <div className="banner__background-shape"></div>
          <div className="banner__main-image">
            <img 
              src={require('../../assets/images/banner_1.png')}
              alt={t('banner.altText.mainImage')}
            />
          </div>
          <div className="banner__secondary-image">
            <img 
              src={require('../../assets/images/banner_2.png')}
              alt={t('banner.altText.secondaryImage')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;