// AboutSection.jsx
import React from 'react';
import { Rocket, Eye } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import './about-section.scss';

const AboutCard = ({ icon: Icon, title, description }) => {
  return (
    <div className="about__card">
      <div className="about__card-header">
        <div className='about__card-container'>
          <Icon size={35} className="about__card-icon" />
        </div>
      </div>
      <div>
        <h3 className="about__card-title">{title}</h3>
        <p className="about__card-text">{description}</p>
      </div>
    </div>
  );
};

const AboutSection = () => {
  const { t } = useTranslation();

  const cards = [
    {
      icon: Rocket,
      title: t('about.mission.title'),
      description: t('about.mission.description')
    },
    {
      icon: Eye,
      title: t('about.vision.title'),
      description: t('about.vision.description')
    }
  ];

  return (
    <section className="about" id='about'>
      <div className="about__container">
        <div className="about__images">
          <div className="about__main-image">
            <img 
              src={require('../../assets/images/about_1.png')}
              alt={t('about.altText.mainImage')}
            />
            <div className="about__experience-badge">
              {t('about.experienceBadge.years')}<br />{t('about.experienceBadge.text')}
            </div>
          </div>
          <div className="about__secondary-image">
            <img 
              src={require('../../assets/images/about_2.png')}
              alt={t('about.altText.secondaryImage')}
            />
          </div>
        </div>

        <div className="about__content">
          <h2 className="about__title">{t('about.title')}</h2>
          
          <p className="about__description">
            {t('about.description')}
          </p>

          <div className="about__cards">
            {cards.map((card, index) => (
              <AboutCard
                key={index}
                icon={card.icon}
                title={card.title}
                description={card.description}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;