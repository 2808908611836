import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TestimonialCard from '../TestimonialCard/TestimonialCard';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import './testimonial-section.scss';

const TestimonialSection = () => {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);

  const testimonials = t('testimonials.items', { returnObjects: true }).map((item, index) => ({
    image: require(`../../assets/images/testimonials/testimonial_${index}.png`),
    name: item.name,
    type: item.type,
    rating: 5,
    review: item.review
  }));

  const nextSlide = () => {
    setCurrentSlide((prev) => 
      prev === testimonials.length - 3 ? 0 : prev + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => 
      prev === 0 ? testimonials.length - 3 : prev - 1
    );
  };

  return (
    <section className="testimonial-section">
      <div className="testimonial-section__container">
        <h2 className="testimonial-section__title">
          {t('testimonials.title')}
        </h2>

        <div className="testimonial-section__carousel">
          <button 
            className="testimonial-section__nav testimonial-section__nav--prev"
            onClick={prevSlide}
            aria-label={t('testimonials.navigation.previous')}
          >
            <ChevronLeft size={16} />
          </button>

          <div className="testimonial-section__cards">
            <div 
              className="testimonial-section__track"
              style={{ 
                transform: `translateX(-${currentSlide * (100 / 3)}%)`,
              }}
            >
              {testimonials.map((testimonial, index) => (
                <div 
                  key={index}
                  className="testimonial-section__slide"
                >
                  <TestimonialCard {...testimonial} />
                </div>
              ))}
            </div>
          </div>

          <button 
            className="testimonial-section__nav testimonial-section__nav--next"
            onClick={nextSlide}
            aria-label={t('testimonials.navigation.next')}
          >
            <ChevronRight size={16} />
          </button>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;