import React from 'react';
import PublicationCard from '../PublicationCard/PublicationCard';
import publicationsData from '../../data/publications.json';
import './publication.scss';

const PublicationSection = () => {
  return (
    <section className="publication-section">
      <div className="publication-section__grid">
        {publicationsData.publications.map(pub => (
          <PublicationCard
            key={pub.id}
            {...pub}
          />
        ))}
      </div>
    </section>
  );
};

export default PublicationSection;