import React from 'react';
import { useTranslation } from 'react-i18next';
import { Facebook, Twitter, Instagram, Youtube, Mail, Phone, Linkedin } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import './footer.scss';

const Footer = () => {
  const { t } = useTranslation();

  const socialLinks = [
    { icon: Linkedin, label: 'facebook', href: 'https://www.linkedin.com/company/30163384/admin/page-posts/published' },
    { icon: Twitter, label: 'twitter', href: 'https://x.com/gic_space' },
    { icon: Instagram, label: 'instagram', href: 'https://www.instagram.com/gicmed07/' },
    { icon: Youtube, label: 'youtube', href: 'https://www.youtube.com/watch?v=pAWoi3lwJS4&amp;t=164s' }
  ];

  const quickLinks = [
    { key: 'about', href: '#about' },
    { key: 'products', href: '/products' },
    { key: 'research', href: '/publications' },
    { key: 'contact', href: '/contact' }
  ];

  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__content">
          {/* Company Info */}
          <div className="footer__company">
            <div className="footer__logo">
              <img 
                src={require('../../assets/logo/logo_white.png')} 
                alt={t('footer.company.logoAlt')} 
              />
            </div>
            <p className="footer__description">
              {t('footer.company.description')}
            </p>
            <div className="footer__social">
              {socialLinks.map(({ icon: Icon, label, href }) => (
                <a 
                  key={label}
                  href={href} 
                  className="footer__social-link" 
                  aria-label={t(`footer.social.${label}`)}
                >
                  <Icon size={20} />
                </a>
              ))}
            </div>
          </div>

          {/* Quick Links */}
          <div className="footer__links">
            <h3 className="footer__title">{t('footer.quickLinks.title')}</h3>
            <ul className="footer__list">
              {quickLinks.map(({ key, href }) => (
                <li key={key}>
                  <Link to={href}>{t(`footer.quickLinks.${key}`)}</Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Info */}
          <div className="footer__contact">
            <h3 className="footer__title">{t('footer.contact.title')}</h3>
            <ul className="footer__list footer__list--contact">
              <li>
                <a 
                  href={`tel:${t('footer.contact.phone.number')}`} 
                  className="footer__contact-link"
                  aria-label={t('footer.contact.phone.label')}
                >
                  <Phone size={16} className="footer__contact-icon" />
                  <span>{t('footer.contact.phone.number')}</span>
                </a>
              </li>
              <li>
                <a 
                  href={`mailto:${t('footer.contact.email.address')}`} 
                  className="footer__contact-link"
                  aria-label={t('footer.contact.email.label')}
                >
                  <Mail size={16} className="footer__contact-icon" />
                  <span>{t('footer.contact.email.address')}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="footer__bottom">
          <p className="footer__copyright">
            {t('footer.copyright')}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;