import React from 'react';
import './product-section-two-card.scss';

const ProductSectionTwoCard = ({ icon, title, description }) => {
  return (
    <div className="product-card">
      <div className="product-card__icon-wrapper">
        <img src={icon} alt={title} className="product-card__icon" />
      </div>
      <h3 className="product-card__title">{title}</h3>
      <p className="product-card__description">{description}</p>
    </div>
  );
};


export default ProductSectionTwoCard;
