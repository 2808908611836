import React from 'react';
import { FileText, Heart } from 'lucide-react';
import './product-section-one.scss';

const StatCard = ({ icon, percentage, description }) => (
  <div className="stat-card">
    {icon}
    <div className="stat-card__content">
      <h3 className="stat-card__percentage">{percentage}%</h3>
      <p className="stat-card__description">{description}</p>
    </div>
  </div>
);

const ProductSectionOne = () => {
  return (
    <section className="product-section-one">
      <div className="product-section-one__container">
        <div className="product-section-one__content">
          <h2 className="product-section-one__title">
            THE BURDEN OF CERVICAL CANCER
          </h2>
          
          <p className="product-section-one__subtitle">
            Cervical cancer is the second most common cancer in women worldwide and the leading cause of cancer deaths in developing countries.
          </p>

          <div className="product-section-one__stats">
            <StatCard
              icon={<FileText />}
              percentage={83}
              description="of all new cases that occur annually"
            />
            <StatCard
              icon={<Heart />}
              percentage={85}
              description="of all 270,000 annual deaths from the disease occur in developing countries."
            />
          </div>

          <p className="product-section-one__description">
            Cervical cancer is the most common cancer among women in sub-Saharan Africa, and the increase disease and deaths is due to limited access to screening, diagnosis and treatment services for target women. The incidence is on the increase in many countries. We are solving this with the most cost effective and innovative Med-Tech solutions.
          </p>
        </div>

        <div className="product-section-one__images">
          <div className="product-section-one__image-main">
            <img 
               src={require('../../assets/images/products/product_1.png')}
              alt="Medical professional demonstrating cervical model"
            />
          </div>
          <div className="product-section-one__image-secondary">
            <img 
              src={require('../../assets/images/products/product_2.png')}
              alt="Cervical cancer awareness demonstration"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductSectionOne;