import React from 'react';
import ProductSectionTwoCard from '../ProductSectionTwoCard/ProductSectionTwoCard';
import './product-section-two.scss';

const ProductSectionTwo = () => {
  const features = [
    {
      icon: require('../../assets/images/products/card_1.png'),
      title: "Point-of-Care Innovation",
      description: "Brings cervical cancer screening directly to patients, enabling immediate, comfortable, and accessible diagnostics even in remote and resource-limited settings."
    },
    {
      icon: require('../../assets/images/products/card_2.png'),
      title: "Adapted to Local Context",
      description: "Easy to use with minimal training required, making it accessible for all healthcare providers, including nurses."
    },
    {
      icon: require('../../assets/images/products/card_3.png'),
      title: "Unique Design",
      description: "A colposcope + a speculum in a single portable device."
    },
    {
      icon: require('../../assets/images/products/card_4.png'),
      title: "Clinically Validated",
      description: "99% of women prefer the C-Spec compared to any other speculum. Better visibility of the cervix, with best comfort reported."
    }
  ];

  return (
    <section className="product-section-two">
      <div className="product-section-two__container">
        <h2 className="product-section-two__main-title">
          WHY THE C-SPEC (SMART SPECULUM) IS A GAME-CHANGER
        </h2>

        <div className="product-section-two__hero">
          <div className="product-section-two__image-wrapper">
            <img 
              src={require('../../assets/images/products/product_2_2.png')}
              alt="C-SPEC Smart Speculum demonstration"
              className="product-section-two__image"
            />
          </div>
          <div className="product-section-two__content">
            <h3 className="product-section-two__title">
              THE C-SPEC SMART SPECULUM
            </h3>
            <p className="product-section-two__description">
              an amazing technological breakthrough, with a harnessed advanced smartphone application combined to state-of-the-art medical engineering, to produce a device serving both as a colposcope and a speculum. The world's first Smart Speculum. With C-Spec, gynecological examinations are more comfortable and efficient like never before.
            </p>
          </div>
        </div>

        <div className="product-section-two__features">
          {features.map((feature, index) => (
            <ProductSectionTwoCard
              key={index}
              {...feature}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProductSectionTwo;