import React from 'react';
import './testimonial-card.scss';

const StarRating = ({ rating }) => {
  return (
    <div className="star-rating">
      {[1, 2, 3, 4, 5].map((star) => (
        <span 
          key={star}
          className={`star ${star <= rating ? 'star--filled' : ''}`}
        >
          ★
        </span>
      ))}
    </div>
  );
};

const TestimonialCard = ({ image, name, type, rating, review }) => {
  return (
    <div className="testimonial-card">
      <div className="testimonial-card__quote-icon">❝</div>
      <div className="testimonial-card__content">
        <div className="testimonial-card__header">
          <img 
            src={image} 
            alt={name} 
            className="testimonial-card__avatar"
          />
          <div className="testimonial-card__info">
            <h3 className="testimonial-card__name">{name}</h3>
            <p className="testimonial-card__type">{type}</p>
          </div>
        </div>
        <StarRating rating={rating} />
        <p className="testimonial-card__review">{review}</p>
      </div>
    </div>
  );
};

export default TestimonialCard;