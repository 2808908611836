import React from 'react';
import { Check } from 'lucide-react';
import './product-section-three.scss';

const ProductSectionThree = () => {
  const features = [
    {
      title: "Better visibility of the cervix for clinicians",
      description: null
    },
    {
      title: "Excellent visualization of vaginal walls:",
      description: "just like an endoscopic viewing"
    },
    {
      title: "Excellent comfort for patients",
      description: null
    },
    {
      title: "More than 85% cheaper than colposcopes",
      description: null
    },
    {
      title: "Unique design:",
      description: "Speculum and Colposcope in a single portable design"
    },
    {
      title: "Advanced mHealth and Telemedicine integrated including AI possibilities",
      description: null
    },
    {
      title: "Extends to cover a wide range of medical applications: Cervical cancer, FGS, Routine speculum exams, STIs, etc",
      description: null
    }
  ];

  return (
    <section className="product-section-three">
      <div className="product-section-three__container">
        <h2 className="product-section-three__title">
          RESULTS, BACKED BY EVIDENCE-BASED RESEARCH
        </h2>

        <div className="product-section-three__content">
          <div className="product-section-three__features">
            {features.map((feature, index) => (
              <div key={index} className="product-section-three__feature">
                <Check className="product-section-three__check" size={20} />
                <div className="product-section-three__feature-text">
                  <span className="product-section-three__feature-title">
                    {feature.title}
                  </span>
                  {feature.description && (
                    <span className="product-section-three__feature-description">
                      {feature.description}
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className="product-section-three__comparison">
            <img 
              src={require('../../assets/images/products/product_3.png')}
              alt="Comparison between Traditional Speculum and C-Spec"
              className="product-section-three__comparison-image"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductSectionThree;