import React from 'react';
import { Link } from 'react-router-dom';
import './page-header.scss';

const PageHeader = ({ title }) => {
  return (
    <header className="page-header">
      <div className="page-header__container">
        <h1 className="page-header__title">{title}</h1>
        <div className="page-header__breadcrumbs">
          <Link to="/" className="page-header__breadcrumb-link">Home</Link>
          <span className="page-header__breadcrumb-separator">›</span>
          <span className="page-header__breadcrumb-current">{title}</span>
        </div>
      </div>
    </header>
  );
};

export default PageHeader;
