import React from 'react';
import { Navbar, PageHeader } from '../../components';

const About = () => {
    return (
        <div>
            <Navbar />
            <PageHeader title="About" />
            <h1>CONTACT</h1>
        </div>
    );
}

export default About;
