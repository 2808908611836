import React from 'react';
import { Navbar, PageHeader, ContactSection, Footer } from '../../components';


const Contact = () => {
    return (
        <div>
            <Navbar />
            <PageHeader title="Contact" />
            <ContactSection />
            <Footer />
        </div>
    );
}

export default Contact;
