import React from 'react';
import './future-item.scss';

const FutureItem = ({ image, title, description, link, imagePosition = 'left' }) => {
  return (
    <div className={`future-item ${imagePosition === 'right' ? 'future-item--reverse' : ''}`}>
      <div className="future-item__image-container">
        <img src={image} alt={title} className="future-item__image" />
      </div>
      
      <div className="future-item__content">
        <h3 className="future-item__title">{title}</h3>
        <p className="future-item__description">{description}</p>
        {/* <a href={link} className="future-item__link" target="_blank" rel="noopener noreferrer">
          {link}
        </a> */}
        <div className='product-item__buttons'>
            <button 
                className={`product-item__button`}
                onClick={()=> window.open(`${link}`, '_blank')}
                >
                Learn More
            </button>
        </div>
      </div>
    </div>
  );
};

export default FutureItem;